<template>
  <div class="prod-center">
    <div class="h">
      <h1 align="center" class="animate__animated animate__bounceInRight">
        PRODUCT CENTER
      </h1>
      <h2 align="center" class="animate__animated animate__bounceInRight">
        产品中心
      </h2>
    </div>

    <div class="prod-content">
      <a-row class="row-list-top">
        <a-col
          :span="6"
          class="top-item"
          v-for="(item, i) in rowListTopData"
          :key="item.id"
          @mouseover="switchItem(item, i)"
          @mouseout="outswitchItem(item, i)"
        >
          <img :src="item.isShowDefIcon ? item.icon : item.defaultIcon" />
          <p class="p1">{{ item.h }}</p>
          <p class="p2">{{ item.p }}</p>
        </a-col>
      </a-row>

      <!-- 服务类型 -->
      <h2 align="center" class="h-2">{{ typeH }}</h2>
      <a-row class="list-top-item" type="flex" justify="space-between">
        <a-col
          :span="4"
          class="item-ele"
          v-for="tItem in typeList"
          :key="tItem.dId"
        >
          <div>
            <img :src="tItem.itemImg" />
            <p>{{ tItem.itemP }}</p>
          </div>
        </a-col>
      </a-row>

      <!-- 应用 -->
      <h2 align="center" class="h-2">应用行业</h2>
      <a-row class="p-list-bottom" type="flex" justify="space-around">
        <a-col
          :span="5"
          class="bottom-item"
          v-for="item in appList"
          :key="item.appId"
          :style="{ background: 'url(' + item.sty + ') no-repeat 50% 50%' }"
        >
          <p>{{ item.appName }}</p>
          <p>{{ item.appDes }}</p>
          <div class="item-mask">
            <img src="../assets/image/802.png" />
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      rowListTopData: [
        {
          id: 1,
          isShowDefIcon: false,
          defaultIcon: require("../assets/image/32655.png"),
          icon: require("../assets/image/794.png"),
          h: "硬件系统工程服务",
          p: "Hardware system engineering services",
          dataItem: [
            {
              dId: 101,
              pid: 1,
              itemImg: require("../assets/image/384.png"),
              itemP: "控制器和机器人应用",
            },
            {
              id: 102,
              pid: 1,
              itemImg: require("../assets/image/386.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 103,
              pid: 1,
              itemImg: require("../assets/image/454.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 104,
              pid: 1,
              itemImg: require("../assets/image/388.png"),
              itemP: "系统工程设计和选型计算",
            },
            {
              id: 105,
              pid: 1,
              itemImg: require("../assets/image/385.png"),
              itemP: "系统工程现场安装和调试",
            },
          ],
          application: [
            {
              appId: 1999,
              pId: 1,
              appName: "半导体制造",
              appDes: "Semiconductor manufacturing",
              sty: require("../assets/image/798.png"),
            },
            {
              appId: 1998,
              pId: 1,
              appName: "汽车工业",
              appDes: "automotive industry",
              sty: require("../assets/image/799.png"),
            },
            {
              appId: 1997,
              pId: 1,
              appName: "硬件制造",
              appDes: "Hardware manufacturing",
              sty: require("../assets/image/800.png"),
            },
            {
              appId: 1996,
              pId: 1,
              appName: "能源信息",
              appDes: "Energy information",
              sty: require("../assets/image/801.png"),
            },
          ],
        },
        {
          id: 2,
          isShowDefIcon: false,
          defaultIcon: require("../assets/image/32654.png"),
          icon: require("../assets/image/797.png"),
          h: "工艺工程",
          p: "Process engineering",
          dataItem: [
            {
              id: 201,
              pid: 2,
              itemImg: require("../assets/image/384.png"),
              itemP: "控制器和机器人应用2",
            },
            {
              id: 202,
              pid: 2,
              itemImg: require("../assets/image/386.png"),
              itemP: "自动化盘柜产品设计生产2",
            },
            {
              id: 203,
              pid: 2,
              itemImg: require("../assets/image/454.png"),
              itemP: "自动化盘柜产品设计生产2",
            },
            {
              id: 204,
              pid: 2,
              itemImg: require("../assets/image/388.png"),
              itemP: "系统工程设计和选型计算2",
            },
            {
              id: 205,
              pid: 2,
              itemImg: require("../assets/image/385.png"),
              itemP: "系统工程现场安装和调试2",
            },
          ],
          application: [
            {
              appId: 2999,
              pId: 2,
              appName: "半导体制造",
              appDes: "Semiconductor manufacturing",
              sty: require("../assets/image/798.png"),
            },
            {
              appId: 2998,
              pId: 2,
              appName: "汽车工业",
              appDes: "automotive industry",
              sty: require("../assets/image/799.png"),
            },
            {
              appId: 2997,
              pId: 2,
              appName: "硬件制造",
              appDes: "Hardware manufacturing",
              sty: require("../assets/image/800.png"),
            },
            {
              appId: 2996,
              pId: 2,
              appName: "能源信息",
              appDes: "Energy information",
              sty: require("../assets/image/801.png"),
            },
          ],
        },
        {
          id: 3,
          isShowDefIcon: false,
          defaultIcon: require("../assets/image/32653.png"),
          icon: require("../assets/image/796.png"),
          h: "电气工程",
          p: "electrical engineering",
          dataItem: [
            {
              id: 301,
              pid: 3,
              itemImg: require("../assets/image/384.png"),
              itemP: "控制器和机器人应用",
            },
            {
              id: 302,
              pid: 3,
              itemImg: require("../assets/image/386.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 303,
              pid: 3,
              itemImg: require("../assets/image/454.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 304,
              pid: 3,
              itemImg: require("../assets/image/388.png"),
              itemP: "系统工程设计和选型计算",
            },
            {
              id: 305,
              pid: 3,
              itemImg: require("../assets/image/385.png"),
              itemP: "系统工程现场安装和调试",
            },
          ],
          application: [
            {
              appId: 3999,
              pId: 3,
              appName: "半导体制造",
              appDes: "Semiconductor manufacturing",
              sty: require("../assets/image/798.png"),
            },
            {
              appId: 3998,
              pId: 3,
              appName: "汽车工业",
              appDes: "automotive industry",
              sty: require("../assets/image/799.png"),
            },
            {
              appId: 3997,
              pId: 3,
              appName: "硬件制造",
              appDes: "Hardware manufacturing",
              sty: require("../assets/image/800.png"),
            },
            {
              appId: 3996,
              pId: 3,
              appName: "能源信息",
              appDes: "Energy information",
              sty: require("../assets/image/801.png"),
            },
          ],
        },
        {
          id: 4,
          isShowDefIcon: false,
          defaultIcon: require("../assets/image/32652.png"),
          icon: require("../assets/image/795.png"),
          h: "自动化解决方案",
          p: "Automation solutions",
          dataItem: [
            {
              id: 401,
              pid: 4,
              itemImg: require("../assets/image/384.png"),
              itemP: "控制器和机器人应用",
            },
            {
              id: 402,
              pid: 4,
              itemImg: require("../assets/image/386.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 403,
              pid: 4,
              itemImg: require("../assets/image/454.png"),
              itemP: "自动化盘柜产品设计生产",
            },
            {
              id: 404,
              pid: 4,
              itemImg: require("../assets/image/388.png"),
              itemP: "系统工程设计和选型计算",
            },
            {
              id: 405,
              pid: 4,
              itemImg: require("../assets/image/385.png"),
              itemP: "系统工程现场安装和调试",
            },
          ],
          application: [
            {
              appId: 4999,
              pId: 4,
              appName: "半导体制造",
              appDes: "Semiconductor manufacturing",
              sty: require("../assets/image/798.png"),
            },
            {
              appId: 4998,
              pId: 4,
              appName: "汽车工业",
              appDes: "automotive industry",
              sty: require("../assets/image/799.png"),
            },
            {
              appId: 4997,
              pId: 4,
              appName: "硬件制造",
              appDes: "Hardware manufacturing",
              sty: require("../assets/image/800.png"),
            },
            {
              appId: 4996,
              pId: 4,
              appName: "能源信息",
              appDes: "Energy information",
              sty: require("../assets/image/801.png"),
            },
          ],
        },
      ],
      typeH: "",
      typeList: [],
      appList: [],
      showIcon: "",
      // isShowDefIcon: true
    };
  },
  methods: {
    switchItem(item, i) {
      // console.log(item);
      this.typeH = item.h;
      this.rowListTopData.forEach((element, index) => {
        element.isShowDefIcon = index === i;
      });
      // console.log(this.rowListTopData);
      this.typeList = item.dataItem;
      this.appList = item.application;
    },
    outswitchItem(item, i) {
      this.rowListTopData[i].isShowDefIcon =
        !this.rowListTopData[i].isShowDefIcon;
    },
  },
};
</script>

<style lang="less">
.prod-center {
  position: relative;
  .h {
    width: 100%;
    position: absolute;
    top: -320px;
    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }
    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }
  .prod-content {
    .row-list-top {
      width: 1440px;
      margin: 0 auto;
      top: -40px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 10px 20px #cccccc;

      .top-item {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .p1 {
          margin-top: 24px;
          margin-bottom: 0px;
          font-size: 22px;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        .p2 {
          margin-top: 8px;
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
        }
        &:hover {
          color: #ffffff;
          background: url("../assets/image/793.png");
          transition: all 0.5s ease-out;
        }
      }
    }
    .h-2 {
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      margin-top: 20px;
    }
    .list-top-item {
      width: 1440px;
      margin: 0 auto;
      margin-top: 60px;
      .item-ele {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        div {
          p {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
          }
        }
        .loop(@i,@j) when (@i < 3) {
          .loop((@i + 1),(@j - 1));
          &:nth-child(@{i}),
          &:nth-child(@{j}) {
            top: -(abs(@i - 3) * 60px);
          }
        }
        .loop(1, 5);
      }
    }
    .p-list-bottom {
      width: 1440px;
      margin: 0 auto;
      height: 380px;
      margin-bottom: 50px;
      position: relative;
      cursor: pointer;
      .bottom-item {
        border-radius: 20px;
        p {
          color: #ffffff;
          margin-bottom: 5px;
          margin-left: 35px;
          z-index: 10;
          position: relative;
          &:nth-child(1) {
            margin-top: 35px;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: bold;
          }
          &:nth-child(2) {
            font-size: 17px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
        }
        .item-mask {
          width: 100%;
          height: 100%;
          // background: cyan;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background-color: rgba(0, 0, 0, 0.6);
          opacity: 0;
          img {
            display: inline-block;
          }
          transition: 0.5s opacity ease;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
